export default {
	transparent: 'transparent',
	current: 'currentColor',
	black: '#000000',
	white: '#FFFFFF',
	light: '#f3f4f8',
	dark: '#263340',
	link: '#0066CC',

	headline: '#1A202C',

	whiteAlpha: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.06)',
		200: 'rgba(255, 255, 255, 0.08)',
		300: 'rgba(255, 255, 255, 0.16)',
		400: 'rgba(255, 255, 255, 0.24)',
		500: 'rgba(255, 255, 255, 0.36)',
		600: 'rgba(255, 255, 255, 0.48)',
		700: 'rgba(255, 255, 255, 0.64)',
		800: 'rgba(255, 255, 255, 0.80)',
		900: 'rgba(255, 255, 255, 0.92)',
	},

	blackAlpha: {
		50: 'rgba(0, 0, 0, 0.04)',
		100: 'rgba(0, 0, 0, 0.06)',
		200: 'rgba(0, 0, 0, 0.08)',
		300: 'rgba(0, 0, 0, 0.16)',
		400: 'rgba(0, 0, 0, 0.24)',
		500: 'rgba(0, 0, 0, 0.36)',
		600: 'rgba(0, 0, 0, 0.48)',
		700: 'rgba(0, 0, 0, 0.64)',
		800: 'rgba(0, 0, 0, 0.80)',
		900: 'rgba(0, 0, 0, 0.92)',
	},
	blueAlpha: {
		50: 'rgba(75, 190, 255, 0.05)',
		100: 'rgba(75, 190, 255, 0.1)',
		200: 'rgba(75, 190, 255, 0.2)',
		300: 'rgba(75, 190, 255, 0.3)',
		400: 'rgba(75, 190, 255, 0.4)',
		500: 'rgba(75, 190, 255, 0.5)',
		600: 'rgba(75, 190, 255, 0.6)',
		700: 'rgba(75, 190, 255, 0.7)',
		800: 'rgba(75, 190, 255, 0.8)',
		900: 'rgba(75, 190, 255, 0.9)',
	},
	blue: {
		50: '#E5EFFA',
		100: '#C5EBFF',
		200: '#99C2EB',
		300: '#66A3E0',
		400: '#3484D6',
		500: '#0066CC',
		600: '#004D99',
		700: '#003973',
		800: '#00264D',
		900: '#001A33',
	},
	red: {
		50: '#FFE5ED',
		100: '#FFB8CE',
		200: '#FF8AAE',
		300: '#FF5C8F',
		400: '#FF2E6F',
		500: '#FF0050',
		600: '#CC0040',
		700: '#990030',
		800: '#660020',
		900: '#330010',
	},

	orange: {
		50: '#FFF0E5',
		100: '#FFD4B8',
		200: '#FFB88A',
		300: '#FF9D5C',
		400: '#FF812E',
		500: '#FF6600',
		600: '#CC5100',
		700: '#993D00',
		800: '#662900',
		900: '#331400',
	},
	yellow: {
		50: '#FFF5E5',
		100: '#FFE4B8',
		200: '#FFD28A',
		300: '#FFC05C',
		400: '#FFAF2E',
		500: '#FF9D00',
		600: '#CC7E00',
		700: '#995E00',
		800: '#663F00',
		900: '#331F00',
	},
	green: {
		50: '#EAFAF1',
		100: '#C5F2D8',
		200: '#9FEABF',
		300: '#7AE1A5',
		400: '#04D898',
		500: '#07c58c',
		600: '#25A75C',
		700: '#1C7D45',
		800: '#13532E',
		900: '#092A17',
	},
	teal: {
		50: '#ECF9F7',
		100: '#C9EEE9',
		200: '#A6E2DA',
		300: '#84D7CC',
		400: '#61CCBD',
		500: '#3EC1AF',
		600: '#329A8C',
		700: '#257469',
		800: '#194D46',
		900: '#0C2723',
	},
	cyan: {
		50: '#E5F6FF',
		100: '#B8E7FF',
		200: '#8AD7FF',
		300: '#5CC7FF',
		400: '#2EB7FF',
		500: '#00A8FF',
		600: '#0086CC',
		700: '#006599',
		800: '#004366',
		900: '#002233',
	},
	purple: {
		50: '#F5EBF9',
		100: '#E3C8EF',
		200: '#D1A4E5',
		300: '#BF81DA',
		400: '#AD5DD0',
		500: '#9B39C6',
		600: '#7C2E9E',
		700: '#5D2277',
		800: '#3E174F',
		900: '#1F0B28',
	},
	pink: {
		50: '#FFF5F7',
		100: '#FED7E2',
		200: '#FBB6CE',
		300: '#F687B3',
		400: '#ED64A6',
		500: '#D53F8C',
		600: '#B83280',
		700: '#97266D',
		800: '#702459',
		900: '#521B41',
	},
	gray: {
		50: '#F2F3F5',
		100: '#E7E9F1',
		200: '#DDDFE7',
		300: '#B5BCC3',
		400: '#9DA6AF',
		500: '#84909B',
		600: '#6C7A88',
		700: '#536374',
		800: '#3B4E60',
		900: '#0A2239',
	},

	devtools: {
		lightBg: '#7c7c7c',
		darkBg: 'gray.700',
	},
}
