import { INVOICES, COUNTRY, CART, LOCATION, PRODUCT, PAYMENT_INTENT } from './constants'

const fetchInvoices = (data) => ({
	type: INVOICES.FETCH_REQUESTED,
	payload: data,
})

const getCart = (data) => ({
	type: CART.FETCH_REQUESTED,
	payload: data,
})

const updateCart = (data) => ({
	type: CART.UPDATE_REQUESTED,
	payload: data,
})

const addItemToCart = (data) => ({
	type: CART.ADD_ITEM_REQUESTED,
	payload: data,
})
const removeItemFromCart = (data) => ({
	type: CART.REMOVE_ITEM_REQUESTED,
	payload: data,
})

const getProducts = (data) => ({
	type: PRODUCT.FETCH_REQUESTED,
	payload: data,
})

/** Get User's location by IP */
const getLocation = (data) => ({
	type: LOCATION.FETCH_REQUESTED,
	payload: data,
})

const postPaymentIntent = (data) => ({
	type: PAYMENT_INTENT.ADD_REQUESTED,
	payload: data,
})
const updatePaymentIntent = (data) => ({
	type: PAYMENT_INTENT.UPDATE_REQUESTED,
	payload: data,
})

const verifyPostalCode = (params) => ({
	type: LOCATION.VERIFY_POSTAL_CODE_REQUESTED,
	payload: params,
})

const getCountry = (countryCode) => ({
	type: COUNTRY.FETCH_REQUESTED,
	payload: countryCode,
})

const getAllCountries = () => ({
	type: COUNTRY.FETCH_ALL_REQUESTED,
	payload: {},
})

const postFreeCart = (data) => ({
	type: CART.POST_FREE_REQUESTED,
	payload: data,
})

const postCheckoutSession = (data) => ({
	type: CART.POST_CHECKOUT_SESSION_REQUESTED,
	payload: data,
})

export {
	fetchInvoices,
	getCart,
	updateCart,
	addItemToCart,
	removeItemFromCart,
	getLocation,
	getProducts,
	postPaymentIntent,
	updatePaymentIntent,
	verifyPostalCode,
	getCountry,
	getAllCountries,
	postFreeCart,
	postCheckoutSession,
}
